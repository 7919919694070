<template>
  <div v-if="aplicacionPolizaResponse.length > 0">
    <div
      v-for="item in aplicacionPolizaResponse"
      :key="item.id"
      class="alert alert-success alert-dismissible fade show"
      role="alert"
    >
      <h5 class="alert-heading">{{ item.file }}</h5>

      {{ item.result.message }}

      <button class="close" type="button" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PolizasIEResponse',

  computed: mapState('polizasIEModule', ['aplicacionPolizaResponse'])
}
</script>

<style lang="scss" scoped></style>
